import { useState, useEffect, useCallback } from 'react';
import { parseDateObject } from '../utils/date';
import { useToken, logout } from './login';

export function useContactsData() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const { token, setAccessToken, setRefreshToken } = useToken();

    const fetchData = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
        try {
            const headers = new Headers();
            headers.append('x-access-token', `${token}`);
            
            const result = await fetch(`https://server.martamoraesshop.com.br/contacts`, {
                headers: headers
            });

            if (!result.ok) {
                if (result.status === 401) {
                    logout(setAccessToken, setRefreshToken);
                } else {
                    const error = await result.json();
                    throw new Error(error.message);
                }
            } else { 
                const parsedData = await result.json();
                if (parsedData.length < 1) {
                    throw new Error('Sem dados para esta busca.');
                } else {
                    setData(parsedData);
                    setIsLoading(false);
                }
            }
        } catch (e) {
            setIsError(true);
            console.error(e);
            setIsLoading(false);
            setError(e.message);
        } 
    }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [{data, isLoading, isError, error}, setData, setIsLoading, setIsError, setError];
}

export function useSeasonalityData(startDate, endDate) {
    const [interval, setInterval] = useState({
        startDate: startDate,
        endDate: endDate
    })
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const { token, setAccessToken, setRefreshToken } = useToken();

    const getData = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);

        // Parse date values so that the database can read them
        let parsedStart;
        let parsedEnd;

        if (typeof interval.startDate === 'object') {
            parsedStart = parseDateObject(interval.startDate);
        } else {
            parsedStart = interval.startDate;
        }

        if (typeof interval.endDate === 'object') {
            parsedEnd = parseDateObject(interval.endDate);
        } else {
            parsedEnd = interval.endDate;
        }

        try {
            const headers = new Headers();
            headers.append('x-access-token', `${token}`);

            const result = await fetch(`https://server.martamoraesshop.com.br/seasonality?startDate=${parsedStart}&endDate=${parsedEnd}`, {
                headers: headers
            });
            
            if (!result.ok) {
                if (result.status === 401) {
                    logout(setAccessToken, setRefreshToken);
                } else {
                    const error = await result.json();
                    throw new Error(error.message);
                }
            } else {
                const parsedData = await result.json();
                if (parsedData.length < 1) {
                    throw new Error('Sem dados de compra para este período.')
                } else {
                    setData(parsedData);
                    setIsLoading(false);  
                }
            }

        } catch (e) {
            console.error(e);
            setIsError(true);
            setIsLoading(false);
            setError(e.message);
        }

        setIsLoading(false);
    }, [interval.startDate, interval.endDate, token]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData();
    }, [getData]);


    return [{data, isLoading, isError, error}, setInterval];
}

export function useSingleContactData(id) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const { token, setAccessToken, setRefreshToken } = useToken();

    const getData = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
       
        try {
            const headers = new Headers();
            headers.append('x-access-token', `${token}`);

            const result = await fetch(`https://server.martamoraesshop.com.br/contacts/${id}`, {
                headers: headers
            });
            
            if (!result.ok) {
                if (result.status === 401) {
                    logout(setAccessToken, setRefreshToken);
                } else {
                    const error = await result.json();
                    throw new Error(error.message);
                }
            } else {
                const parsedData = await result.json();

                if (parsedData.length < 1) {
                    throw new Error('Sem dados de compra para este contato.')
                } else {
                    setData(parsedData);
                    setIsLoading(false);
                }
            }
        } catch (e) {
            console.error(e);
            setIsError(true);
            setIsLoading(false);
            setError(e.message);
        }

        setIsLoading(false);
    }, [token, id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData();
    }, [getData]);

    return [data, isLoading, isError, error];
}

export function useSearchData(query, context) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const searchQuery = async (query) => {
            try {
                const response = await fetch(`https://server.martamoraesshop.com.br/search?q=${query}&c=${context}`);

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const results = await response.json();
                    setData(results);
                }
                
            } catch (e) {
                console.error(e);
            }
        }

        let timeout = setTimeout(() => { // debouncing the query for better performance
            searchQuery(query)    
        }, 500)

        return () => {
            clearTimeout(timeout)
        }

    }, [query, context]);

    return data;
}
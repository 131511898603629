export function getFormCategories() {
    return [
        'blusas', 
        'vestidos',
        'calças',
        'macacão',
        'acessórios',
        'saias',
        'conjuntos',
        'shorts'
    ]
}

export function getFormSizes() {
    return [
        'u',
        'p',
        'm',
        'g',
        'gg'
    ]
}

export function getFormStates() {
    return [
        'ac', 'al', 'ap', 'am', 'ba',
        'ce', 'df', 'es', 'go', 'ma',
        'mt', 'ms', 'mg', 'pa', 'pb',
        'pr', 'pe', 'pi', 'rj', 'rn',
        'rs', 'ro', 'rr', 'sc', 'sp',
        'se', 'to'

    ]
}

export function getFormStatus() {
    return [
        'Aberto',
        'Pago',
        'Embalado',
        'Enviado',
        'Recebido',
        'Devolução Enviada',
        'Devolução Recebida',
        'Troca Enviada',
        'Troca Recebida',
        'Finalizado',
        'Cancelado'
    ]
}
import './DonutLegend.css';
import { isInArray } from '../../utils/array';

function DonutLegend({ products, colorScheme }) {
    // Create array that contains unique product sizes
    const items = [];
    products.forEach(product => {
        if (!isInArray(product.size, items)) {
            items.push(product.size);
        }
    });

    // Renders a square for every size in the array with the proper color
    return (
        <div className='donut-legend-wrapper'>
            {items.map((d, i) => (
                <div className='donut-legend-item' key={i}>
                    <p>{d}</p>
                    <div style={{backgroundColor: colorScheme.get(d)}}></div>
                </div>
            ))}
        </div>
    );
}

export default DonutLegend;
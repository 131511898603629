import { scaleUtc, scaleLinear } from "d3-scale";
import { line } from 'd3-shape';
import { max, extent } from 'd3-array';
import { findMaxSold } from "../../utils/data";
import { useChartDimensions } from "../../hooks/chart";
import Spinner from '../../components/Spinner/Spinner';
import AxisLeft from '../AxisLeft/AxisLeft';
import AxisBottom from '../AxisBottom/AxisBottom';
import LegendRight from "../LegendRight/LegendRight";

function LineChart({ data, isLoading }) {
    const [ref, dimensions] = useChartDimensions({})


    const xScale = scaleUtc().domain(extent(data, d => new Date(d.date))).range([0, dimensions.innerWidth]);
    const yScale1 = scaleLinear().domain([max(data, d => d.nova), 0]).range([0, dimensions.innerHeight]);
    const yScale2 = scaleLinear().domain([max(data, d => d.recorrente), 0]).range([0, dimensions.innerHeight]);

    const line1 = line().x(d => xScale(new Date(d.date))).y(d => yScale1(d.nova));
    const line2 = line().x(d => xScale(new Date(d.date))).y(d => yScale2(d.recorrente));

    
    return (
        <div className='individual-graph-wrapper' ref={ref} style={{height: 350}}>
            {!isLoading ? <svg width={dimensions.width} height={dimensions.height}>
                <g transform={`translate(${[dimensions.marginLeft, dimensions.marginTop].join(',')})`}>
                    <path d={line1(data)} fill='none' stroke='#1b355c' strokeWidth={1} />
                    <path d={line2(data)} fill='none' stroke='#fa7f72' strokeWidth={1} />
                    <g transform={`translate(${[
                        0,
                        0
                    ].join(",")})`}>
                    <AxisLeft domain={[findMaxSold(data), 0]} range={yScale1.range()} scaleType={'money'} />
                    </g>
                    <g transform={`translate(${[
                        0,
                        dimensions.innerHeight
                    ].join(",")})`}>
                        <AxisBottom domain={xScale.domain()} range={xScale.range()}  scaleType={'time'} />
                    </g>
                    <g transform={`translate(${[
                        dimensions.innerWidth,
                        0
                    ].join(",")})`}>
                        <LegendRight data={null} type={'revenue'}/>
                    </g>
                </g>
            </svg> : <Spinner color={'black'}/>}
        </div>
    );
}

export default LineChart;
import { arc, pie } from 'd3-shape';
import { flatGroup } from 'd3-array';
import { lazy, Suspense } from 'react';
import DonutLegend from '../../components/DonutLegend/DonutLegend';
import { countSize, extractSizeData } from '../../utils/data';
import { createColorScheme } from '../../utils/color';

// Code splitting
const Vestidos = lazy(() => import('../icons/Vestidos'));
const Calças = lazy(() => import('../icons/Calças'));
const Blusas = lazy(() => import('../icons/Blusas'));
const Acessórios = lazy(() => import('../icons/Acessórios'));
const Conjuntos = lazy(() => import('../icons/Conjuntos'));
const Saias = lazy(() => import('../icons/Saias'));
const Shorts = lazy(() => import('../icons/Shorts'));


function ClothesArc({ data }) {
    // Define svg container size
    const height = 250;
    const width = 250;

    // Extract the relevant data for the Clothes Donuts chart
    const sizes = extractSizeData(data);
    
    // Group the data by category
    const group = flatGroup(sizes, d => d.category);

    // Function that renders the correct category svg icon
    function renderCategoryIcon(category, width, height) {
        switch(category) {
            case 'Vestidos':
                return <Vestidos width={width} height={height} />
            case 'Blusas':
                return <Blusas width={width} height={height} />
            case 'Calças':
                return <Calças width={width} height={height} />
            case 'Acessórios':
                return <Acessórios width={width} height={height} />
            case 'Conjuntos':
                return <Conjuntos width={width} height={height} />
            case 'Saias':
                return <Saias width={width} height={height} />
            case 'Shorts':
                return <Shorts width={width} height={height} />
            default:
                return <></>
        }
    }

    // Map with color values for each size
    const colorScheme = createColorScheme('size');

    return (
        <>
            <DonutLegend products={sizes} colorScheme={colorScheme} />
            {group.map(cat => {
                const category = cat[0];
                const countArray = countSize(cat);
                const pieArcData = pie().value(d => d.count)(countArray);
                const arcPie = arc().innerRadius(70).outerRadius(100);

                return (
                    <svg width={width} height={height} key={category}>
                        <Suspense fallback={'Loading...'}>
                            {renderCategoryIcon(category, width, height)}
                        </Suspense>
                        {pieArcData.map(d => (
                            <g>
                                <path key={d.data.size} d={arcPie(d)} transform={`translate(${width/2}, ${height/2})`} fill={colorScheme.get(d.data.size)} stroke={'black'}/>
                                {/* <text fill={'black'} transform={`translate(${arcPie.centroid(d)})`}>
                                    <tspan x={'0'} font-size={'24'}>{d.data.size}</tspan>
                                </text> */}
                            </g>
                        ))}
                    </svg>
                )
            })}
        </>
    );
}

export default ClothesArc;
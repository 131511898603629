import { createColorScheme } from "../../utils/color";
import { isInArray } from "../../utils/array";

function LegendRight({ data, type }) {
    // Define variable that will store the color scheme map and the iterable items
    let colorScheme
    const items = []

    if (type === 'size') {
        colorScheme = createColorScheme(type)
        data.forEach(datum => {
            if (!isInArray(datum.size, items)) {
                items.push(datum.size);
            }
        })

    } else if (type === 'revenue') {
        colorScheme = createColorScheme(type)
        items.push('Nova')
        items.push('Recorrente')

    } else if (type === 'category') {
        colorScheme = createColorScheme(type)
        data.forEach(datum => {
            items.push(datum.category);
        });
    }

    return (
        <>
            {items.map((d, i) => ( 
                <g key={d}>
                    <rect y={20 * i} width={15} height={15} fill={colorScheme.get(d)} />
                    <text y={20 * i} fontSize={15} style={{transform: 'translate(20px, 12px)'}}>{d}</text>
                </g>
            ))}
        </>
    )
}

export default LegendRight;
import { useState } from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';
import { Link } from 'react-router-dom';
import { parseDateString } from '../../utils/date';
import { getTotalOrderPrice, formatCurrency } from '../../utils/price';
import './Accordion.css'

function Accordion({ data, context }) {
    const [clicked, setClicked] = useState('0');

    function handleToggle(index) {
        if (clicked === index) {
            return setClicked('0')
        }
        setClicked(index);
    }

    return (
        <ul className='accordion'>
            {data.map((element, i) => (
                <AccordionItem 
                key={i}
                title={context === 'multiple' ? element.nome + ' ' + element.sobrenome : parseDateString(element.data)} 
                onToggle={() => handleToggle(i)} 
                active={clicked === i}
                >
                    {context === 'single' ?
                    <div className='accordion-info'>
                        {element.produtos.map(produto => (
                            <>
                                <p className='product-info'><strong>{produto.produto}</strong></p>
                                <p className='product-info'><strong>Tamanho:</strong> {produto.tamanho}</p>
                                <p className='product-info'><strong>Cor:</strong> {produto.cor}</p>
                                <p className='product-info'><strong>Quantidade:</strong> {produto.quantidade}</p>
                                <p className='product-info'><strong>Preço:</strong> R$ {produto.preço - (produto.preço * produto.desconto)}</p>
                            </>
                        ))}
                        <p><strong>Desconto:</strong> {element.desconto * 100}%</p>
                        <p><strong>Total:</strong> R$ {formatCurrency(getTotalOrderPrice(element))}</p>
                        <p><strong>Status:</strong> {element.status}</p>
                    </div>
                    :
                    <div className="accordion-info">
                        <p><strong>WhatsApp:</strong> +{element.whatsapp}</p>
                        <p><strong>Email:</strong> {element.email}</p>
                        <p><strong>Cidade:</strong> {element.cidade + '/' + element.estado}</p>
                        <p><strong>Última compra:</strong> {element.ultimaCompra ? parseDateString(element.ultimaCompra) : 'N/a'}</p> 
                        <button className="ver-mais-button">
                            <Link to={`${element.id}`} style={{ textDecoration: 'none', color: '#fcfcfc', fontWeight: 600 }}>Ver mais</Link>
                        </button>
                    </div>}
                </AccordionItem>
            ))}
        </ul>
    );
}

export default Accordion;
import Profile from '../../components/icons/Profile';
import ClothesDonut from '../../components/ClothesDonut/ClothesDonut';
import BarChart from '../../components/BarChart/BarChart';
import Accordion from '../../components/Accordion/Accordion';
import Spinner from '../../components/Spinner/Spinner';
import ErrorWarning from '../../components/ErrorWarning/ErrorWarning';
import { useOutletContext } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { utcParse } from 'd3-time-format';
import { timeMonth } from 'd3-time';
import { parseDateString } from '../../utils/date';
import { getTotalSpend, formatCurrency } from '../../utils/price';
import { useSingleContactData } from '../../hooks/data';
import './ClientPage.css'

function ClientPage() {
    const navigate = useNavigate();
    const device = useOutletContext();
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const [data, isLoading, isError, error] = useSingleContactData(id);
    
    const parseTime = utcParse('%Y-%m-%d');

    function countTime(compras) {
        
        const count = timeMonth.count(parseTime(compras[compras.length - 1].data), parseTime(compras[0].data));

        if (count === 0) {
            return 0;
        } else {
            return Math.floor((compras.length / count) * 10) / 10;
        }
    }
  
    return (
        <>
            {isError ? <ErrorWarning error={error} /> : <></>}
            <div className='general-info-wrapper'>
                <div className="single-contact-info">
                    <div className="back-button-wrapper">
                        <button className="back-button" onClick={() => navigate(-1)}>Voltar</button>
                    </div>
                    <div className="info-wrapper">
                        <Profile />
                        <div className='info'>
                            {data.map(d => (
                            <>
                                <h1>{`${d.nome} ${d.sobrenome}`}</h1>
                                <p>WhatsApp: +{`${d.whatsapp}`}</p>
                                <p>Email: {`${d.email}`}</p>
                                <p>Cidade: {`${d.cidade}/${d.estado}`}</p>
                                <p>Última compra: {parseDateString(data[0].compras[0].data)}</p>
                            </>
                            ))}
                        </div>
                        <button className='edit-contact-button'>Editar</button>
                    </div>
                </div>
                <div className='aggregate-buy-info'>
                    {!isLoading ? data.map(d => (
                    <>
                        <div className='total-orders'>
                            <h3>Total em compras</h3>
                            <p>R$ {formatCurrency(getTotalSpend(d.compras))}</p>
                        </div>
                        <div className='orders-per-month'>
                            <h3>Compras por mês</h3>
                            <p>{countTime(d.compras)}</p>
                        </div>
                    </>
                    )) : <Spinner color={'black'} />}
                </div>
            </div>
            <h3>Pedidos por tamanho:</h3>
            <div className="single-contact-charts">
                {!isLoading ? data.map(d => {return device === 'desktop' ? <BarChart data={d.compras} /> : <ClothesDonut data={d.compras} />}) : <Spinner color={'black'}/>}
            </div>
            <h3>Histórico de compras:</h3>
            <div className='accordion-wrapper'>
                {!isLoading ? data.map(d => <Accordion data={d.compras} context={'single'}/>) : <Spinner color={'black'} />}
            </div>
        </>
    );
}

export default ClientPage;
import './AccordionItem.css';

function AccordionItem({ title, onToggle, active, children }) {
    
    return (
        <li className={`accordion-item ${active ? 'active' : ''}`}>
            <button className="accordion-button" onClick={onToggle}>
                {title}
                <span className="accordion-switch">{'<'}</span>
            </button>
            <div className={`accordion-info-wrapper ${active ? 'open' : ''}`}>
                {children}
            </div>
        </li>
    );
}

export default AccordionItem;
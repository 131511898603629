export function getTotalOrderPrice(order) {
    let total = 0;
    order.produtos.forEach(produto => {
        let subtotal = parseFloat(produto.preço) * produto.quantidade;
        subtotal -= subtotal * parseFloat(produto.desconto).toFixed(4);
        total += subtotal;
    });
    total -= total * parseFloat(order.desconto).toFixed(4);
    return total;
}

export function getTotalSpend(orders) {
    let spend = 0;
    orders.forEach(order => {
        const total = getTotalOrderPrice(order);
        spend += total;
    });

    return spend;
}

export function formatCurrency(currency) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BLR'
    });

    const formated =  formatter.format(currency);
    let finalForm = formated.substring(4);

    return finalForm;
}
export function createColorScheme(type) {
    if (type === 'revenue') {
        const map = new Map();
        map.set('Nova', '#1b355c');
        map.set('Recorrente', '#fa7f72');
        return map;

    } else if (type === 'category') {
        const map = new Map();
        map.set('Vestidos', '#fa7f72');
        map.set('Blusas', '#1b355c');
        map.set('Calças', '#e0d466');
        map.set('Acessórios', '#8666e0');
        map.set('Conjuntos', '#66e0d9');
        map.set('Saias', '#6681e0');
        map.set('Shorts', '#853c13');
        map.set('Macacão', '#f307bf');
        return map;

    } else if (type === 'size') {
        const map = new Map();
        map.set('GG', '#83df97');
        map.set('G', '#1b355c');
        map.set('M', '#fa7f72');
        map.set('P', '#b486ef');
        map.set('U', '#e0d466');
        return map;

    }
}
import { useRouteError } from 'react-router-dom';
import './ErrorPage.css'

function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Opa</h1>
            <p>Parece que alguma coisa deu errado.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export default ErrorPage;
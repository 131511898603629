// Import utils functions used inside other functions located here
import { getTotalOrderPrice } from '../utils/price';

export function getTotalSales(data) {
    let sum = 0;

    // Loop through every order
    for (let i = 0; i < data.length; i++) {
        // Calculate total by looping through every product in every order
        let total = 0;
        data[i].produtos.forEach(product => {
            let subtotal = product.preço * product.quantidade;
            subtotal -= subtotal * product.desconto;
            total += subtotal;
        });
        total -= total * data[i].desconto;
        sum += total;
    }

    // Return total amount
    return sum;
}

export function getBarData(data) {
    // Set array to store the data
    const arr = [];

    // Loop through all orders, getting the amount sold per category
    for (let i = 0; i < data.length; i++) {
        data[i].produtos.forEach(produto => {
            let value = produto.quantidade * produto.preço;
            value -= value * produto.desconto;
            value -= value * data[i].desconto;

            const categoryInArray = arr.find(o => {
                if (o.category === produto.categoria) {
                    return true
                }

                return false
            })

            if (!categoryInArray) {
                arr.push({category: produto.categoria, amount: value});
            } else {
                arr.find(o => {
                    if (o.category === produto.categoria) {
                        return o.amount += value;
                    }
                    return null
                });
            }
        });
    }

    return arr;
}

export function getLineData(data) {
    // Set array to store the data, and a variable to help with the data parsing
    const arr = [];
    let prev;

    // Loop through the data and store the date along with the amount sold on it
    data.forEach(datum => {
        if (datum.data === prev) {
            if (datum.tipo === 'Nova') {
                arr[arr.length-1].nova += getTotalOrderPrice(datum);
            } else {
                arr[arr.length-1].recorrente += getTotalOrderPrice(datum);
            }
        } else {
            if (datum.tipo === 'Nova') {
                arr.push({date: datum.data, nova: getTotalOrderPrice(datum), recorrente: 0});
                prev = datum.data;
            } else {
                arr.push({date: datum.data, nova: 0, recorrente: getTotalOrderPrice(datum)});
                prev = datum.data;
            }
        }
    });

    return arr;
}

export function findMaxSold(data) {
    let max = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].nova > data[i].recorrente) {
            if (data[i].nova > max) {
                max = data[i].nova;
            }
        } else if (data[i].nova < data[i].recorrente) {
            if (data[i].recorrente > max) {
                max = data[i].recorrente;
            }
        } else {
            if (data[i].nova > max) {
                max = data[i].nova;
            }
        }
    }

    return max;
}

export function countSales(data) {
    // Set up array with needed values (only two known beforehand, so its ok)
    const arr = [
        {type: "Nova", count: 0},
        {type: "Recorrente", count: 0}
    ];
    
    // Count occurrences of each value in the data
    data.forEach(element => {
        if (element.tipo === "Nova") {
            arr[0].count++;
        } else {
            arr[1].count++;
        }
    });

    return arr;
}

export function countCategory(data) {
    // Get all category values inside a single array as objects
    const cats = [];
    for (let i = 0; i < data.length; i++) {
        data[i].produtos.forEach(produto => {
            cats.push({category: produto.categoria});
        });
    }

    // Count all ocurrences of the categories in the previous array
    const count = {};
    cats.forEach(obj => {
        const key = `${obj.category}`;
        if (!count[key]) {
            count[key] = {...obj, count: 0};
        }
        count[key].count += 1;
    });

    // Extract the previous result into an array
    return Object.values(count);
}

export function countSize(data) {
    // Separate the sizes from a category into an array as objects
    const arr = [];
    for (let i = 0; i < data[1].length; i++) {
        arr.push({size: data[1][i].size});
    }
    
    // Create object containing the count of every size present in the previous array
    const count = {};
    arr.forEach(obj => {
        const key = `${obj.size}`;
        if (!count[key]) {
            count[key] = {...obj, count: 0};
        }
        count[key].count += 1;
    });
    
    // Return array with extracted values from previous object
    return Object.values(count);
}

// Declare function for finding max value in a grouped category dataset
export function findMaxCount(categories) {
    // Put count of all sizes per category into an array
    const arr = [];
    categories.forEach(category => {
        arr.push(countSize(category));
    });
    
    // Loop through that array and find the maximum possible value it has
    let max = 0;
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
            if (arr[i][j].count > max) {
                max = arr[i][j].count;
            }
        }
    }

    return max;
}

// Declare function for extracting size data from the general dataset
export function extractSizeData(data) {
    const sizes = [];
    data.forEach(datum => {
        datum.produtos.forEach(produto => {
            for (let i = 0; i < produto.quantidade; i++) {
                sizes.push({ category: produto.categoria, size: produto.tamanho });
            }
        });
    });

    return sizes;
}
import './App.css';
import NavBar from '../../components/NavBar/NavBar';
import LoginPage from '../login/LoginPage';
import { Outlet } from 'react-router-dom';
import { useWindowWidth } from '../../hooks/window';
import { useToken } from '../../hooks/login';
import { useEffect } from 'react';

function App() {
  const {token, refresh, setAccessToken, setRefreshToken} = useToken();
  const device = useWindowWidth() > 820 ? 'desktop' : 'mobile';

  useEffect(() => {
    async function updateToken() {
      try {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const response = await fetch('https://server.martamoraesshop.com.br/refresh', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({refreshToken: refresh})
        });

        if (!response.ok) {
          throw Error('Erro de revalidação de tokens.');
        } else {
          const token = await response.json();

          setAccessToken(token);
          setRefreshToken(token);
        }

      } catch (e) {
        console.error(e);
        setAccessToken(null);
        setRefreshToken(null);
        localStorage.clear();
      }
    }

    const minutes = 1000 * 60 * 19;

    const interval = setInterval(() => {
      if (token) {
        updateToken();
      }
    }, minutes);

    return () => clearInterval(interval);
  }, [token, refresh, setAccessToken, setRefreshToken]);

  if (!token) return <LoginPage setAccessToken={setAccessToken} setRefreshToken={setRefreshToken} />
  
  return (
    <div className="App">
      <header className="App-header">
        <NavBar device={device} />
      </header>
      <section className='App-content'>
        <Outlet context={device} />
      </section>
    </div>
  );
}

export default App;

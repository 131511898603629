import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './TimeInterval.css';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import Watch from '../icons/Watch';
import { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';

function TimeInterval({ refetch }) {
    const [activePicker, setActivePicker] = useState('');
    const [interval, setInterval] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: '#fa7f72',
            showDateDisplay: false
        }
    ]);

    const pickerRef = useRef();

    // Whenever the user clicks outside of the component, hide it
    useEffect(() => {
        function clickOutside(e) {
            if (activePicker && !pickerRef.current.contains(e.target)) {
                setActivePicker('');
            }
        }

        document.addEventListener('click', clickOutside);

        return () => {
            document.removeEventListener('click', clickOutside);
        }
    }, [activePicker]);

    // Whenever the user changes the time interval using the picker, refetch the data
    useEffect(() => {
        refetch({startDate: interval[0].startDate, endDate: interval[0].endDate});
    }, [interval, refetch]);

    return (
        <div className="date-range-wrapper" ref={pickerRef}>
            <div className="date-range-input" onClick={() => setActivePicker('active')}>
                <Watch />
                <p>{format(interval[0].startDate, 'dd/MM/yyyy')}</p>
            </div>
            <div className="date-range-input" onClick={() => setActivePicker('active')}>
                <Watch />
                {interval[0].endDate ?
                <p>{format(interval[0].endDate, 'dd/MM/yyyy')}</p>
                : <p>{format(interval[0].startDate, 'dd/MM/yyyy')}</p>
                }
            </div>
            
            <DateRange
            className={`date-range-picker ${activePicker}`}
            editableDateInputs={true}
            retainEndDateOnFirstSelection={true}
            ranges={interval}
            onChange={item => setInterval([item.selection])}
            locale={ptBR}
            direction={'vertical'}
            />

        </div>
    );
}

export default TimeInterval;
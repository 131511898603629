import './FormSelection.css';

function FormSelection({ item, domain, unselectItem }) {
    return (
        
        <div className='form-selection-flex-container'>
            <button onClick={() => unselectItem(item, domain)}>x</button>
            <p><strong>{item}</strong></p>
        </div>
        
    );
}

export default FormSelection;
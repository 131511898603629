import './FilterForm.css';
import { getFormCategories, getFormSizes, getFormStates, getFormStatus } from '../../../src/utils/form';
import { isInArray } from '../../utils/array';
import { useState, useRef, useEffect } from 'react';
import { useToken, logout } from '../../hooks/login';
import Spinner from '../../components/Spinner/Spinner';
import FormSelection from '../FormSelection/FormSelection';

function FilterForm({ active, setActive, setData }) {
    // Initial state with default values for all user inputs
    const [selectedItems, setSelectedItems] = useState({
        timeInterval: {
            startTime: '2021-01-01',
            endTime: '2025-12-31'
        },
        categories: [],
        sizes: [],
        colors: [],
        states: [],
        cities: [],
        spend: {
            startSpend: 0,
            endSpend: 5000
        },
        status: []
    });
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const formRef = useRef();
    const { token, setAccessToken, setRefreshToken } = useToken();

    
    useEffect(() => {
        const searchQuery = async (query) => {
            if (query !== '' && (search === 'colors' || search === 'cities')) {
                try {
                    const response = await fetch(`https://server.martamoraesshop.com.br/search?q=${query}&c=${search}`);
    
                    if (!response.ok) {
                        const error = await response.json();
                        throw new Error(error.message);
                    } else {
                        const results = await response.json();
                        setResult(results);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }

        let timeout = setTimeout(() => { // debouncing the query for better performance
            searchQuery(query)    
        }, 500)

        return () => {
            clearTimeout(timeout)
        }

    }, [query, setData, search, setResult]);

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        setIsError(false);
        try {
            const url = 'https://server.martamoraesshop.com.br/filter';
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('x-access-token', `${token}`);

            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: headers,
                body: JSON.stringify(selectedItems)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    logout(setAccessToken, setRefreshToken);
                } else {
                    const error = await response.json();
                    throw new Error(error.message);
                }
            } else {
                const newData = await response.json();
            
                setData(newData);
                setSelectedItems({
                    timeInterval: {
                        startTime: '2021-01-01',
                        endTime: '2025-12-31'
                    },
                    categories: [],
                    sizes: [],
                    colors: [],
                    states: [],
                    cities: [],
                    spend: {
                        startSpend: 0,
                        endSpend: 5000
                    },
                    status: []
                });
                setActive(false);
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
            setIsError(true);
            setIsLoading(false);
            setError(e.message);
        }
    }

    // Function for removing an item from one of the state arrays
    function removeFromState(val, key) {
        setSelectedItems({
            ...selectedItems,
            [key]: selectedItems[key].filter(element => element !== val)
            }
        );
    }

    // Function that updates the state according to the input type
    function handleSelectChange(e) {
        if (e.target.name === 'start-time-filter') {
            setSelectedItems({
                ...selectedItems,
                timeInterval: {
                    ...selectedItems.timeInterval,
                    startTime: e.target.value
                }
            });
        } else if (e.target.name === 'end-time-filter') {
            setSelectedItems({
                ...selectedItems,
                timeInterval: {
                    ...selectedItems.timeInterval,
                    endTime: e.target.value
                }
            });
        } else if (e.target.name === 'start-spend-filter') {
            setSelectedItems({
                ...selectedItems,
                spend: {
                    ...selectedItems.spend,
                    startSpend: e.target.value
                }
            });
        } else if (e.target.name === 'end-spend-filter') {
            setSelectedItems({
                ...selectedItems,
                spend: {
                    ...selectedItems.spend,
                    endSpend: e.target.value
                }
            });
        } else {
            if (!isInArray(e.target.value, selectedItems[e.target.name])) {
                setSelectedItems({
                    ...selectedItems,
                    [e.target.name]: [
                        ...selectedItems[e.target.name],
                        e.target.value
                    ]
                });
            }
        }

    }

    function handleSearchChange(e) {
        setQuery(e.target.value);
        setSearch(e.target.name);
    }

    function handleSearchClick(e, context) {
        setSelectedItems({
            ...selectedItems,
            [context]: [
                ...selectedItems[context],
                e.target.innerText
            ]
        });
    }
    
    return (
        <div className={`filter-form-wrapper ${active ? 'active' : ''}`} ref={formRef}>
            <h3>Filtrar Contatos</h3>
            <button onClick={() => setActive(false)}></button>
            <form className='filter-form' onSubmit={(e) => handleSubmit(e)}>
                <label htmlFor='time-filter'>
                    <p>Intervalo de Tempo</p>
                    <input name='start-time-filter' id='start-time-filter' onChange={handleSelectChange}/>
                    <input name='end-time-filter' id='end-time-filter' onChange={handleSelectChange}/>
                </label>
                <label htmlFor='categories'>
                    <span className='form-selection-container'>
                        <p>Categorias</p>
                        {selectedItems.categories && selectedItems.categories.map(v => (
                            <FormSelection unselectItem={removeFromState} domain={'categories'} key={v} item={v} />
                        ))}
                    </span>
                    <select name='categories' id='categories' onChange={handleSelectChange}>
                        <option>Escolher</option>
                        {getFormCategories().map(d => {
                            return <option key={d} value={d}>{d.charAt(0).toUpperCase() + d.slice(1)}</option>
                        })}
                    </select>
                </label>
                <label htmlFor='sizes'>
                    <span className='form-selection-container'>
                        <p>Tamanhos</p>
                        {selectedItems.sizes && selectedItems.sizes.map(v => (
                            <FormSelection unselectItem={removeFromState} domain={'sizes'} key={v} item={v} />
                        ))}
                    </span>
                    <select name='sizes' id='sizes' onChange={handleSelectChange}>
                        <option>Escolher</option>
                        {getFormSizes().map(d => (
                            <option key={d} value={d}>{d.toUpperCase()}</option>
                        ))}
                    </select>
                </label>
                <label htmlFor='colors'>
                    <span className='form-selection-container'>
                        <p>Cores</p>
                        {selectedItems.colors && selectedItems.colors.map(v => (
                            <FormSelection unselectItem={removeFromState} domain={'colors'} key={v} item={v} />
                        ))}
                    </span>
                    <input type='search' name='colors' id='colors' onChange={(e) => handleSearchChange(e)}/>
                    {result !== [] && query !== '' && search === 'colors' &&
                    <div className='filter-search-results'>
                        {result.map(d => (
                            <p onClick={(e) => handleSearchClick(e, 'colors')}>{d.cor}</p>
                        ))}    
                    </div>}
                </label>
                <label htmlFor='states'>
                    <span className='form-selection-container'>
                        <p>Estados</p>
                        {selectedItems.states && selectedItems.states.map(v => (
                            <FormSelection unselectItem={removeFromState} domain={'states'} key={v} item={v} />
                        ))}
                    </span>
                    <select name='states' id='states' onChange={handleSelectChange}>
                        <option>Escolher</option>
                        {getFormStates().map(d => (
                            <option key={d} value={d}>{d.toUpperCase()}</option>
                        ))}
                    </select>
                </label>
                <label htmlFor='cities'>
                    <span className='form-selection-container'>
                        <p>Cidades</p>
                        {selectedItems.cities && selectedItems.cities.map(v => (
                            <FormSelection unselectItem={removeFromState} domain={'cities'} key={v} item={v} />
                        ))}
                    </span>
                    <input type='search' name='cities' id='cities' onChange={(e) => handleSearchChange(e)}/>
                    {result !== [] && query !== '' && search === 'cities' &&
                    <div className='filter-search-results'>
                        {result.map(d => (
                            <p onClick={(e) => handleSearchClick(e, 'cities')}>{d.cidade}</p>
                        ))}    
                    </div>}
                </label>
                {/* <label htmlFor='spend-filter'>
                    <p>Valor Gasto</p>
                    <input name='start-spend-filter' id='start-spend-filter' onChange={handleSelectChange}/>
                    <input name='end-spend-filter' id='end-spend-filter' onChange={handleSelectChange}/>
                </label> */}
                <label htmlFor='status'>
                    <span className='form-selection-container'>
                        <p>Status do Pedido</p>
                        {selectedItems.status && selectedItems.status.map(v => (
                                <FormSelection unselectItem={removeFromState} domain={'status'} key={v} item={v} />
                            ))}
                    </span>
                    <select name='status' id='status' onChange={handleSelectChange}>
                        <option>Escolher</option>
                        {getFormStatus().map(d => (
                            <option key={d} value={d}>{d}</option>
                        ))}
                    </select>
                </label>
                <div id='filter-button-label'>
                    {isLoading ? <Spinner color='white'/> : 
                    isError ? <p>{error}</p> : 
                    <input type='submit' name='filter-button' id='filter-button' value={'Filtrar'}/>}
                </div>
            </form>
        </div>
    );
}

export default FilterForm;
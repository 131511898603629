import './Spinner.css';

function Spinner({ color }) {
    return (
        <div className={`lds-ring ${color}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>  
    );
}

export default Spinner;
export const chartMargin = {
    top: 10,
    right: 0,
    left: 75,
    bottom: 20
}

export function combineChartDimensions(dimensions) {
    // Set default values if they're not passed in
    const parsedDimensions = {
        ...dimensions,
        marginTop: dimensions.marginTop || 10,
        marginRight: dimensions.marginRight || 150,
        marginBottom: dimensions.marginBottom || 40,
        marginLeft: dimensions.marginLeft || 75
    }

    // Return the inner chart dimensions (minus margins and legends)
    return {
        ...parsedDimensions,
        innerHeight: Math.max(
            parsedDimensions.height
            - parsedDimensions.marginTop 
            - parsedDimensions.marginBottom, 
            0
        ),
        innerWidth: Math.max(
            parsedDimensions.width 
            - parsedDimensions.marginLeft 
            - parsedDimensions.marginRight, 
            0
        )
    };
}
import './ContactsPage.css';
import Accordion from '../../components/Accordion/Accordion';
import ContactsTable from '../../components/ContactsTable/ContactsTable';
import Spinner from '../../components/Spinner/Spinner';
import ErrorWarning from '../../components/ErrorWarning/ErrorWarning';
import { useOutletContext } from 'react-router-dom';
import FilterForm from '../../components/FilterForm/FilterForm';
import Filter from '../../components/icons/Filter';
import { useState, useEffect } from 'react';
import  { useContactsData } from '../../hooks/data';
import { useToken, logout } from '../../hooks/login';

function ContactsPage() {
    const [formActive, setFormActive] = useState(false);
    const [{data, isLoading, isError, error}, setData, setIsLoading, setIsError, setError] = useContactsData();
    const [query, setQuery] = useState('');
    const device = useOutletContext();
    const { token, setAccessToken, setRefreshToken } = useToken();
    
    useEffect(() => {
        const searchQuery = async (query) => {
            try {
                if (query.length !== 0) {
                    setIsLoading(true);
                    setIsError(false);
                    const headers = new Headers();
                    headers.append('x-access-token', `${token}`);

                    const response = await fetch(`https://server.martamoraesshop.com.br/searchContact?q=${query}`, {
                        headers: headers
                    });

                    if (!response.ok) {
                        if (response.status === 401) {
                            logout(setAccessToken, setRefreshToken);
                        } else {
                            const error = await response.json();
                            throw new Error(error.message);
                        }
                    } else {
                        const results = await response.json();
                        if (results.length < 1) {
                            throw new Error('Sem dados para esta busca.');
                        } else {
                            setData(results);
                            setIsLoading(false);   
                        }
                    }
                }
                
            } catch (e) {
                console.error(e);
                setIsError(true);
                setIsLoading(false);
                setError(e.message);
            }
        }

        let timeout = setTimeout(() => { // debouncing the query for better performance
            searchQuery(query)    
        }, 500)

        return () => {
            clearTimeout(timeout)
        }

    }, [query, setData, token, setAccessToken, setError, setIsError, setIsLoading, setRefreshToken]);

    function handleChange(e) {
        setQuery(e.target.value)
    }

    return (
        <>
            <FilterForm active={formActive} setActive={setFormActive} setData={setData} />
            <div className="page-config">
                <div className="filter">
                    <button className="config-button" onClick={() => setFormActive(true)}>
                        <Filter />
                    </button>
                </div>
                <div className="search-bar">
                    <input type='search' onChange={handleChange}></input>
                </div>
                <div className="add-contacts">
                    <button className="config-button"></button>
                </div>
            </div>
            <div className='content-wrapper'>
                {isLoading ? <Spinner color={'black'}/> : 
                isError ? <ErrorWarning error={error} /> :
                device === 'desktop' ? <ContactsTable data={data} /> : <Accordion data={data} context={'multiple'} />}
            </div>
        </>
    );
}

export default ContactsPage;
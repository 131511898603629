function Watch() {
    return (
        <svg 
        width={30} 
        height={30} 
        style={
            {
                backgroundColor: '#e3e3e3', 
                fill: 'none', 
                stroke: '#000',
                strokeWidth: 1,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 10
            }
        }>
            <circle cx="15" cy="15" r="12"/>
            <polyline points="15,7 15,16 22,13 "/>
        </svg>
    );
}

export default Watch;
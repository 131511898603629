import './PieLegend.css';

function PieLegend({ data, colorScheme }) {
    
    return (
        <div className='pie-legend-wrapper'>
            {data.map((d, i) => (
                <div className="pie-legend-item" key={i}>
                    <p>{d.data.type || d.data.category}</p>
                    <div style={{backgroundColor: colorScheme.get(d.data.type || d.data.category)}}></div>
                </div>
            ))}
        </div>
    );
}

export default PieLegend;
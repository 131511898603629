import { arc, pie } from 'd3-shape';
import { useEffect, useState, useMemo } from 'react';
import { countSales, countCategory } from '../../utils/data';
import { createColorScheme } from '../../utils/color';
import { useChartDimensions } from '../../hooks/chart';
import PieLegend from '../PieLegend/PieLegend';

function ClothesPie({ data, view }) {
    const [chartData, setChartData] = useState([]);
    const revenueData = useMemo(() => createRevenuePie(data), [data]);
    const categoryData = useMemo(() => createCategoryPie(data), [data]);
    const [ref, dimensions] = useChartDimensions({
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0
    })

    useEffect(() => {
        if (view === 'category') {
            setChartData(categoryData);
        } else {
            setChartData(revenueData);
        }
    }, [view, revenueData, categoryData]);

    // Function for arranging the data to be used on the revenue pie chart
    function createRevenuePie(data) {
        // Return measures for calculating the arcs on the chart
        return pie().value(d => d.count)(countSales(data));
    }

    // Function for arranging the data to be used on the category pie chart
    function createCategoryPie(data) {
        // Return measures for calculating the arcs on the chart
        return pie().value(d => d.count)(countCategory(data));
        
    }

    // Map with color values for each revenue type or category
    const colorScheme = createColorScheme(view);

    // Measures for the circle
    const arcPie = arc().innerRadius(0).outerRadius(150);
    
    return (
        <div className='individual-graph-wrapper' ref={ref} style={{height: 350}}>
            <svg width={dimensions.width} height={dimensions.height}>
                {chartData.map(d => (
                    <path 
                    d={arcPie(d)} 
                    key={d.data.type || d.data.category} 
                    stroke={'none'} 
                    fill={colorScheme.get(d.data.type || d.data.category)}
                    transform={`translate(${[dimensions.width * .5, dimensions.height * .5].join(',')})`}/>
                ))}
            </svg>
            <PieLegend data={chartData} colorScheme={colorScheme}/>
        </div>
    );
}

export default ClothesPie;
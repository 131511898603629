import { ResizeObserver } from '@juggle/resize-observer';
import { combineChartDimensions } from '../utils/chart';
import { useRef, useState, useEffect } from 'react';

export function useChartDimensions(settings) {
    // Sets ref to be used on the chart's wrapper
    const ref = useRef();
    // Sets chart dimensions with both passed in and default settings
    const dimensions = combineChartDimensions(settings);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        // Return specified width and height if they were set by the user
        if (dimensions.width && dimensions.height) return [ref, dimensions]

        const element = ref.current;

        // Use resizeObserver polyfill to work in all browsers
        // This basically watches — or observes, if you will — an element for
        // changes in its dimensions
        const resizeObserver = new ResizeObserver(entries => {
            // Handle edge cases
            if (!Array.isArray(entries)) return;
            if (!entries.length) return;
            
            // Set height and width to be the observed element's
            const entry = entries[0];
            if (width !== entry.contentRect.width) {
                setWidth(entry.contentRect.width);
            }
            if (height !== entry.contentRect.height) {
                setHeight(entry.contentRect.height);
            }
        });

        // Observe the wrapping div
        resizeObserver.observe(element);

        // Cleanup
        return () => resizeObserver.unobserve(element);
    }, [dimensions, ref, height, width]);

    // Recalculate dimensions if they change
    const newSettings = combineChartDimensions({
        ...dimensions,
        width: dimensions.width || width,
        height: dimensions.height || height
    });

    return [ref, newSettings];
}
import './ErrorWarning.css';

function ErrorWarning({ error }) {
    return (
        <div className='error-wrapper'>
            <p className='error-text'>{error}</p>
        </div>
    );
}

export default ErrorWarning;
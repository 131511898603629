import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/root/App';
import ContactsPage from './routes/contacts/ContactsPage';
import SeasonalityPage from './routes/seasons/SeasonalityPage';
import ErrorPage from './routes/error/ErrorPage';
import ClientPage from './routes/single-contact/ClientPage';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'contatos',
        element: <ContactsPage/>
      },
      {
        path: 'sazonalidade',
        element: <SeasonalityPage/>
      },
      {
        path: 'contatos/:contatoId',
        element: <ClientPage/>
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

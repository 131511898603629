import './ContactsTable.css';
import { Link } from 'react-router-dom';
import { parseDateString } from '../../utils/date';

function ContactsTable({ data }) {
    return (
        <table>
            <thead>
                <tr>
                    <td>Nome</td>
                    <td>WhatsApp</td>
                    <td>Email</td>
                    <td>Cidade</td>
                    <td>Última Compra</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {data.map((person) => (
                    <tr>
                        <td>{person.nome + ' ' + person.sobrenome}</td>
                        <td>+{person.whatsapp}</td>
                        <td>{person.email}</td>
                        <td>{person.cidade + '/' + person.estado}</td>
                        <td>{person.ultimaCompra ? parseDateString(person.ultimaCompra) : 'N/a'}</td>
                        <td>
                            <button className='ver-mais-button'>
                                <Link to={`${person.id}`} style={{ textDecoration: 'none', color: '#fcfcfc', fontWeight: 600 }}>Ver mais</Link>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default ContactsTable;
import "./NavBar.css";
import logo from "../../logotipo_oficial513.jpg";
import { Link, useLocation } from "react-router-dom";
import Hamburger from "../icons/Hamburger"
import ChartIcon from "../icons/ChartIcon";
import ContactsIcon from "../icons/ContactsIcon";
import { useState, useEffect, useRef } from "react";

function NavBar({ device }) {
    const [activeMenu, setActiveMenu] = useState("");
    const [activeSeason, setActiveSeason] = useState("");
    const [activeContacts, setActiveContacts] = useState("");
    const location = useLocation();
    const menuRef = useRef();
    
    // Toggle active state based on url path
    useEffect(() => {
        const at = location.pathname.split("/")[1];

        if (at === "contatos") {
            setActiveContacts("active");
        } else if (at === "sazonalidade") {
            setActiveSeason("active");
        }

        return () => {
            setActiveContacts("");
            setActiveSeason("");
        }
    }, [location]);

    // Enable closing the mobile menu when clicking outside of it
    useEffect(() => {
        function clickOutside(e) {
            if (activeMenu && !menuRef.current.contains(e.target)) {
                setActiveMenu("");
            }
        }

        document.addEventListener("click", clickOutside);

        return () => {
            document.removeEventListener("click", clickOutside);
        }
    }, [activeMenu]);

    return (
        device === "desktop" ?
        <nav className="NavBar">
            <img src={logo} className="logo" alt="MMShop logo"/>
            <ul className="nav-icons">
                <li data-testid="sazon-link" className={`page-icon ${activeSeason}`}>
                    <Link to={"sazonalidade"} >
                        <ChartIcon/>
                    </ Link>
                </li>
                <li data-testid="contats-link" className={`page-icon ${activeContacts}`}>
                    <Link to={"contatos"} >
                        <ContactsIcon />
                    </ Link>
                </li>
            </ul>
        </nav>
        :
        <nav className="NavBar">
            <img src={logo} className="logo" alt="MMShop logo"/>
            <button className="hamburger-menu" data-testid="nav-button" onClick={() => setActiveMenu("active")} ref={menuRef}>
                <Hamburger />
                <ul className={`nav-links ${activeMenu}`}>
                    <li className={`${activeSeason}`}>
                        <Link to={"sazonalidade"} >Sazonalidade</Link>
                    </li>
                    <li className={`${activeContacts}`}>
                        <Link to={"contatos"} >Contatos</Link>
                    </li>
                </ul>
            </button>
        </nav>
    );
}

export default NavBar;
import { useState } from 'react';

export function useToken() {
    // Using arrow functions here so it's easier to read at first glance
    const getAccessToken = () => {
        const tokenString = localStorage.getItem('token');
        return tokenString;
    }

    const getRefreshToken = () => {
        const refreshString = localStorage.getItem('refresh');
        return refreshString;
    }

    const [token, setAccessToken] = useState(getAccessToken());
    const [refresh, setRefreshToken] = useState(getRefreshToken());

    const saveAccessToken = (rawToken) => {
        localStorage.setItem('token', rawToken.token);
        setAccessToken(rawToken.token);
    }

    const saveRefreshToken = (rawToken) => {
        localStorage.setItem('refresh', rawToken.refresh);
        setRefreshToken(rawToken.refresh);
    }

    return {
        setAccessToken: saveAccessToken,
        setRefreshToken: saveRefreshToken,
        token,
        refresh
    }
}

export function logout() {
    localStorage.clear();
    window.location.reload(true);
}
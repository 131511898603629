import ViewSwitch from '../../components/ViewSwitch/ViewSwitch';
import ClothesPie from '../../components/ClothesPie/ClothesPie';
import TimeInterval from '../../components/TimeInterval/TimeInterval';
import LineChart from '../../components/LineChart/LineChart';
import SeasonBarChart from '../../components/SeasonBarChart/SeasonBarChart';
import Spinner from '../../components/Spinner/Spinner';
import ErrorWarning from '../../components/ErrorWarning/ErrorWarning';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { formatCurrency } from '../../utils/price';
import { getTotalSales, getLineData, getBarData } from '../../utils/data';
import './SeasonalityPage.css';
import { useSeasonalityData } from '../../hooks/data';

function SeasonalityPage() {
    const [toggle, setToggle] = useState(false);
    const [{data, isLoading, isError, error}, refetch] = useSeasonalityData();
    const device = useOutletContext();

    
    return (
        <>
            {isError ? <ErrorWarning error={error} /> : <></>}
            {device === 'mobile' ? <ViewSwitch isOn={toggle} handleToggle={() => setToggle(!toggle)} /> : <></>}
            <div className='general-sales-info'>
                <TimeInterval refetch={refetch} />
                <div className='sales-wrapper'>
                    <h1>Vendas</h1>
                    <p>R$ {formatCurrency(getTotalSales(data))}</p>
                </div>
            </div>
            <div className='graph-wrapper'>
                {device === 'mobile' ? 
                !isLoading ? <ClothesPie data={data} view={toggle ? 'category' : 'revenue'} /> : <Spinner color={'black'}/> : 
                <>
                    <LineChart data={getLineData(data)} isLoading={isLoading} />
                    <SeasonBarChart data={getBarData(data)} isLoading={isLoading} />
                </>}
            </div>
        </>
    );
}

export default SeasonalityPage;
function ChartIcon() {
    return (
        <svg viewBox="0 0 250 250" fill={"#fcfcfc"}>
            <g id="season-chart">
                <path d="M52.216,58.587c-0.024,0.164-0.097,0.311-0.097,0.482v3.475c0,1.919,1.556,3.475,3.475,3.475s3.475-1.556,3.475-3.475
                V59.85c7.492-0.459,13.464-6.64,13.464-14.245c0-7.307-5.517-13.286-12.592-14.156c-0.285-0.076-0.563-0.176-0.872-0.176
                c-3.832,0-6.949-3.117-6.949-6.949s3.117-6.949,6.949-6.949c3.832,0,6.949,3.117,6.949,6.949c0,1.919,1.556,3.475,3.475,3.475
                s3.475-1.556,3.475-3.475c0-7.664-6.235-13.898-13.898-13.898V6.95c0-1.919-1.556-3.475-3.475-3.475
                c-1.919,0-3.475,1.556-3.475,3.475v5.408c-4.134,2.411-6.949,6.844-6.949,11.965c0,7.068,5.322,12.855,12.159,13.722
                c0.285,0.076,0.562,0.176,0.871,0.176c4.072,0,7.383,3.312,7.383,7.383c0,4.071-3.312,7.383-7.383,7.383
                s-7.383-3.312-7.383-7.383c0-1.919-1.556-3.475-3.475-3.475s-3.475,1.556-3.475,3.475C43.867,51.366,47.303,56.311,52.216,58.587
                z"/>
                <path d="M170.254,38.221c-9.579,0-17.373,7.794-17.373,17.373c0,5.777,2.864,10.869,7.216,14.03l-38.766,81.125
                c-2.056-0.859-4.307-1.341-6.67-1.341c-3.4,0-6.552,1.019-9.234,2.714l-32.684-44.247c2.282-2.94,3.697-6.584,3.697-10.586
                c0-9.579-7.794-17.373-17.373-17.373c-9.579,0-17.373,7.794-17.373,17.373c0,4.434,1.72,8.442,4.464,11.515
                c-0.043,0.044-0.101,0.062-0.142,0.109l-21.424,24.741c-2.206-1.016-4.638-1.621-7.221-1.621C7.794,132.035,0,139.829,0,149.408
                s7.794,17.373,17.373,17.373c9.579,0,17.373-7.794,17.373-17.373c0-4.48-1.75-8.529-4.545-11.614l21.07-24.333
                c0.147-0.169,0.209-0.374,0.317-0.56c2.275,1.095,4.791,1.761,7.48,1.761c3.057,0,5.889-0.862,8.388-2.255l32.904,44.545
                c-1.929,2.799-3.071,6.18-3.071,9.828c0,9.579,7.794,17.373,17.373,17.373c9.579,0,17.373-7.794,17.373-17.373
                c0-4.682-1.881-8.92-4.904-12.048l39.291-82.224c1.237,0.281,2.512,0.458,3.833,0.458c9.579,0,17.373-7.794,17.373-17.373
                C187.628,46.014,179.833,38.221,170.254,38.221z M17.373,159.832c-5.748,0-10.424-4.676-10.424-10.424
                s4.676-10.424,10.424-10.424c2.044,0,3.938,0.613,5.55,1.633c0.045,0.044,0.064,0.103,0.112,0.145
                c0.168,0.145,0.367,0.218,0.551,0.325c2.541,1.903,4.211,4.909,4.211,8.321C27.797,155.156,23.121,159.832,17.373,159.832z
                M59.068,107.713c-5.748,0-10.424-4.676-10.424-10.424c0-5.748,4.676-10.424,10.424-10.424s10.424,4.676,10.424,10.424
                C69.492,103.037,64.816,107.713,59.068,107.713z M114.661,177.204c-5.748,0-10.424-4.676-10.424-10.424
                s4.676-10.424,10.424-10.424c2.032,0,3.915,0.608,5.52,1.617c0.306,0.356,0.68,0.662,1.131,0.877
                c0.016,0.008,0.033,0.004,0.049,0.011c2.258,1.913,3.724,4.734,3.724,7.919C125.085,172.529,120.409,177.204,114.661,177.204z
                M170.254,66.018c-5.748,0-10.424-4.676-10.424-10.424c0-5.748,4.676-10.424,10.424-10.424c5.748,0,10.424,4.676,10.424,10.424
                C180.678,61.342,176.002,66.018,170.254,66.018z"/>
            </g>
        </svg>
    );
}

export default ChartIcon;
import { flatGroup } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import AxisLeft from '../AxisLeft/AxisLeft';
import AxisBottom from '../AxisBottom/AxisBottom';
import LegendRight from '../LegendRight/LegendRight';
import { countSize, findMaxCount, extractSizeData } from '../../utils/data';
import { createColorScheme } from '../../utils/color';
import { useChartDimensions } from "../../hooks/chart";

function BarChart({ data }) {
    const [ref, dimensions] = useChartDimensions({})
    
    // Extract the relevant data for the Clothes Bar chart
    const sizes = extractSizeData(data);

    // Group the data by category
    const group = flatGroup(sizes, d => d.category);
    
    // Map with color values for each size
    const colorScheme = createColorScheme('size');

    // Create X and Y scales to be used on the whole chart
    const categoryBand = scaleBand().domain(group.map(d => d[0])).range([0, dimensions.innerWidth]).round(true);
    const categoryHeight = scaleLinear().domain([findMaxCount(group), 0]).range([0, dimensions.innerHeight]);
    
    return (
        <div className='individual-graph-wrapper' ref={ref} style={{height: 350 }}>
            <svg width={dimensions.width} height={dimensions.height}>
                <g transform={`translate(${[dimensions.marginLeft, dimensions.marginTop].join(',')})`}>
                    {group.map((cat, i) => {
                        // Variable for helping with the inner range of each category
                        // Basically, the range ends where the next category's range starts, unless it's the last one, in which case it ends at the edge of the chart
                        let rangeEnd;
                        if (!group[i+1]) {
                            rangeEnd = categoryBand.range()[1];
                        } else {
                            rangeEnd = categoryBand(group[i+1][0]);
                        }
                        
                        // Create X scales to be used per category
                        const countArray = countSize(cat);
                        const countBand = scaleBand().domain(countArray.map(d => d.size)).range([categoryBand(cat[0]) , rangeEnd]).padding(0.2).round(true);
                        
                        return (
                            <g x={categoryBand(cat[0])} y={dimensions.innerHeight}>
                                
                                {countArray.map(d => {
                                    
                                    return <rect 
                                    key={d} 
                                    x={countBand(d.size)}
                                    y={categoryHeight(d.count)}
                                    width={15}
                                    height={categoryHeight(0) - categoryHeight(d.count)} 
                                    fill={colorScheme.get(d.size)}></rect>
                                })}
                            </g>
                        );
                        
                    })}
                    <g transform={`translate(${[
                        0,
                        0
                    ].join(",")})`}>
                        <AxisLeft domain={categoryHeight.domain()} range={categoryHeight.range()} scaleType={'quantity'} />
                    </g>
                    <g transform={`translate(${[
                        0,
                        dimensions.innerHeight
                    ].join(",")})`}>
                        <AxisBottom domain={categoryBand.domain()} range={categoryBand.range()} scaleType={'band'} />
                    </g>
                    <g transform={`translate(${[
                        dimensions.innerWidth,
                        0
                    ].join(",")})`}>
                        <LegendRight data={sizes} type={'size'}/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default BarChart;
import { scaleBand, scaleLinear } from "d3-scale";
import { max } from 'd3-array';
import { createColorScheme } from "../../utils/color";
import AxisLeft from "../AxisLeft/AxisLeft";
import AxisBottom from "../AxisBottom/AxisBottom";
import { useChartDimensions } from "../../hooks/chart";
import LegendRight from "../LegendRight/LegendRight";
import Spinner from '../../components/Spinner/Spinner';

function SeasonBarChart({ data, isLoading }) {
    const [ref, dimensions] = useChartDimensions({})

    const colorScheme = createColorScheme('category');

    const categoryBand = scaleBand().domain(data.map(d => d.category)).range([0, dimensions.innerWidth]).paddingInner(0.2).paddingOuter(0.4).align(0.5);
    const categoryHeight = scaleLinear().domain([max(data, d => d.amount), 0]).range([0, dimensions.innerHeight]);

    return (
        <div className='individual-graph-wrapper' ref={ref} style={{height: 350}}>
            {!isLoading ? <svg width={dimensions.width} height={dimensions.height}>
                <g transform={`translate(${[dimensions.marginLeft, dimensions.marginTop].join(',')})`}>
                    {data.map(d => (
                        <rect 
                        key={d.category} 
                        x={categoryBand(d.category)} 
                        y={categoryHeight(d.amount)} 
                        width={15} 
                        height={categoryHeight(0) - categoryHeight(d.amount)} 
                        fill={colorScheme.get(d.category)}/>
                    ))}
                    <g transform={`translate(${[
                        0,
                        0
                    ].join(",")})`}>
                        <AxisLeft domain={categoryHeight.domain()} range={categoryHeight.range()} scaleType={'money'}/>
                    </g>
                    <g transform={`translate(${[
                        0,
                        dimensions.innerHeight
                    ].join(",")})`}>
                        <AxisBottom domain={categoryBand.domain()} range={categoryBand.range()} scaleType={'band'} />
                    </g>
                    <g transform={`translate(${[
                        dimensions.innerWidth,
                        0
                    ].join(",")})`}>
                        <LegendRight data={data} type={'category'}/>
                    </g>
                </g>
            </svg> : <Spinner color={'black'}/>}
        </div>
    );
}

export default SeasonBarChart;
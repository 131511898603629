import './ViewSwitch.css';

function ViewSwitch({ handleToggle, isOn}) {
    return (
        <div className='view-switch'>
            <input checked={isOn} type='checkbox' id='view-switch' className='view-switch-checkbox' onChange={handleToggle}/>
            <label className='view-switch-label' htmlFor='view-switch'>
                <span className='view-switch-switch'></span>
            </label>
        </div>
    );
}

export default ViewSwitch;
import './LoginPage.css';
import logo from "../../logotipo_oficial513.jpg";
import Spinner from '../../components/Spinner/Spinner';
import { useState } from 'react';

function LoginPage({ setAccessToken, setRefreshToken }) {
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        setIsError(false);
        try {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');

            const response = await fetch('https://server.martamoraesshop.com.br/login', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({password})
            });


            if (response.status === 401) {
                const error = await response.json();
                throw new Error(error.message);
            } else if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const token = await response.json();
            
                setAccessToken(token);
                setRefreshToken(token);
                setIsLoading(false);
            }
        } catch (e) {
            setIsError(true);
            console.error(e);
            setError(e.message);
            setIsLoading(false);
        }
    }

    return (
        <section className='login-page'>
            {isError && 
            <div className='login-error-message'>
                <p>{error}</p>
            </div>}
            <div className='logo-container'>
                <img src={logo} alt='MMShop logo' className='logo'/>
            </div>
            <form className='login-form' onSubmit={handleSubmit}>
                <label htmlFor='password'>
                    Senha:
                    <input id='password' name='password' type='password' autoComplete='current-password' onChange={(e) => setPassword(e.target.value)}/>
                </label>
                <div className='login-submit-wrapper'>
                    {!isLoading ? <input type='submit' value={'Enviar'}/> : <Spinner color={'white'}/>}
                </div>
            </form>
        </section>
    );
}

export default LoginPage;